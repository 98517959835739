@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --black: #1c1e21;
  --white: #ffffff;
  --tw-color1: #a855f7;
  --tw-color1-hover: #9333ea;
}

body {
  background: var(--black);
  font-family: "Inter", sans-serif;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.logo {
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
}

.logo > svg {
  height: 24px;
}

.container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 3rem;
  font-size: 1rem;
  font-family: 'P22UndergroundCYPro-Light';
  color: #fff;
  width: 180px;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
  transition: all 1s ease;
}

.btn:hover {
 color:#8c7c3d;
 border-color:#8c7c3d;
}

.btn:disabled {
  opacity: 0.5;
}

.address {
  font-size: 1.5rem;
  margin-bottom: 16px;
}
h1 {
  font-family: parisserif-medium;
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 0;
}

.iandatext.external {
 font-family: 'igorandandre';
 font-size: 10em;
}

.mintingBar p {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.5em;
  line-height: 1.75em;
  word-spacing: 0.1em;
  margin-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-family: 'P22UndergroundCY-Light';
}

button.btn {
  background-color: transparent;
  background-image: none;
  border: 1pt solid white;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2rem;
}

.input-style.hidden {
    display: none;
}


.input-style{
    position: relative;
    display: flex;
    width: 100%;
    height: 3.0625rem;
    border-radius: 3.875rem;
    overflow: hidden;
    margin-top: 2.5rem;
}

@media (min-width: 43.8125rem){
    .input-style {
        margin-top: 0;
        width: 20rem;
        margin-bottom: 1.5em;
    }
}

input {
    display: block;
    all: unset;
    background: #f4f4f4;
    width: 100%;
    letter-spacing: -.02em;
    color: #000;
    padding: 0 1.875rem;
    font-family: 'P22UndergroundCY-Light';
    font-size: 1em;
}
.joinbtn{
        width: 100%;
}
.thehidden{display: none; pointer-events: none; visibility: hidden;}
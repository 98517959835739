@font-face {
	font-family:'igorandandre';
	src:url('https://art.igorandandre.com/fonts/V2_0/igorandandre.eot');
	src:url('https://art.igorandandre.com/fonts/V2_0/igorandandre.eot?#iefix') format('embedded-opentype'),url('https://art.igorandandre.com/fonts/V2_0/igorandandre.woff') format('woff'),url('https://art.igorandandre.com/fonts/V2_0/igorandandre.ttf') format('truetype'),url('https://art.igorandandre.com/fonts/V2_0/igorandandre.svg#igorandandre') format('svg');
	font-weight:normal;
	font-style:normal;
}

@font-face {
	font-family:'P22UndergroundCYPro-Light';
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_1_0.eot');
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_1_0.eot?#iefix') format('embedded-opentype'),url('https://art.igorandandre.com/fonts/V2_0/24D8AB_1_0.woff') format('woff'), url('https://art.igorandandre.com/fonts/V2_0/24D8AB_1_0.ttf') format('truetype');
}

@font-face {
	font-family:'P22UndergroundCY-Light';
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_3_0.eot');
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_3_0.eot?#iefix') format('embedded-opentype'), url('https://art.igorandandre.com/fonts/V2_0/24D8AB_3_0.woff') format('woff'), url('https://art.igorandandre.com/fonts/V2_0/24D8AB_3_0.ttf') format('truetype');
}

@font-face {
	font-family:'P22UndergroundCY-LtS';
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_4_0.eot');
	src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_4_0.eot?#iefix') format('embedded-opentype'),url('https://art.igorandandre.com/fonts/V2_0/24D8AB_4_0.woff') format('woff'),url('https://art.igorandandre.com/fonts/V2_0/24D8AB_4_0.ttf') format('truetype');
}

@font-face{font-family:'ParisSerif-Medium';src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_A_0.eot');src:url('https://art.igorandandre.com/fonts/V2_0/24D8AB_A_0.eot?#iefix') format('embedded-opentype'),url('https://art.igorandandre.com/fonts/V2_0/24D8AB_A_0.woff') format('woff'),url('https://art.igorandandre.com/fonts/V2_0/24D8AB_A_0.ttf') format('truetype')}

@font-face{font-family:'SparkyBV';src:url('https://art.igorandandre.com/fonts/V2_0/255842_0_0.eot');src:url('https://art.igorandandre.com/fonts/V2_0/255842_0_0.eot?#iefix') format('embedded-opentype'),url('https://art.igorandandre.com/fonts/V2_0/255842_0_0.woff') format('woff'),url('https://art.igorandandre.com/fonts/V2_0/255842_0_0.ttf') format('truetype')}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
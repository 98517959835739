.detail {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}
@media (min-width: 43.8125rem){
    .detail {
        overflow-y: visible;
        display: grid;
        grid-template-columns: repeat(12,1fr);
        height: 100vh;
    }
}

.detail__align {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

@media (min-width: 43.8125rem){
    .detail__align {
        position: absolute;
    }
}


.detail__image {
    position: relative;
    width: 100vw;
    height: 100vw;
    background: #dadada;
    overflow: hidden;
    width: auto;
    height: auto;
    grid-column: span 7;
    grid-row: 1;
}


.detail__image img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: opacity .4s linear .05s;
    opacity: 1;
}
.page {
    height: 100%;
     min-height: 100vh;
 }
 .css-e8luex {
    max-width: 1.5rem;
    margin-right:.5em
}
button.threeOptions.btn {
    margin: 10px auto;
}
 
 .mintingBar {
     position: relative;
     grid-column: 8/span 5;
     grid-row: 1;
     min-height: calc(100vh - 100vw);
     overflow: hidden;
 }
 .container {
     width: 100%;
     padding: 2.25rem 2.6875rem 3rem;
 }
 
 .mintingBar {
     background-color: rgb(0 187 238);
     transform: translate(0px, 0px);
 }
 
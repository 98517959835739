@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --black: #1c1e21;
  --white: #ffffff;
  --tw-color1: #a855f7;
  --tw-color1-hover: #9333ea;
}

body {
  background: var(--black);
  font-family: "Inter", sans-serif;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.logo {
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
}

.logo > svg {
  height: 24px;
}

.container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 3rem;
  font-size: 1rem;
  font-family: 'P22UndergroundCYPro-Light';
  color: #fff;
  width: 180px;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
  transition: all 1s ease;
}

.btn:hover {
 color:#8c7c3d;
 border-color:#8c7c3d;
}

.btn:disabled {
  opacity: 0.5;
}

.address {
  font-size: 1.5rem;
  margin-bottom: 16px;
}
h1 {
  font-family: parisserif-medium;
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 0;
}


.iandatext.external {
 font-family: 'igorandandre';
 font-size: 10em;
}

.mintingBar p {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.5em;
  line-height: 1.75em;
  word-spacing: 0.1em;
  margin-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-family: 'P22UndergroundCY-Light';
}

button.btn {
  background-color: transparent;
  background-image: none;
  border: 1pt solid white;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2rem;
}
input.chakra-numberinput__field{
  /* display: block; */
  all: unset;
  background: #f4f4f4;
  width: 70px;
  /* letter-spacing: -.02em; */
  color: #000;
  padding: 0 1.875rem;
  font-family: 'P22UndergroundCY-Light';
  cursor: text;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  /* border-radius: 10px; */
  /* border: 2px solid rgb(229, 232, 235); */
  color: rgb(4, 17, 29);
  /* width: 100%; */
  /* padding: 12px; */
  height: 48px;
  /* font-size: 16px; */
  border-radius: 9999px;
  text-align: center;
  margin-right: 1em;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 12px;
}



.mintingRow{
display: flex;
align-content: center;
align-items: center;
justify-content: center;
vertical-align: middle;
}

span.chakra-button__icon.css-1wh2kri, span.mint-btn {
display: flex;
align-self: center;
/* align-content: center; */
align-items: center;
-webkit-box-pack: center;
justify-content: center;
font-size: inherit;
position: relative;
line-height: unset;
vertical-align: middle;
}


.mintAreaRight p {
margin-top: 0;
}

.mintInfoContainer {
margin-top: 2em;
}

.mintAreaLeft {
margin-top: 2em;
margin-bottom: 2em;
vertical-align: middle;
display: flex;
align-content: center;
align-items: center;
justify-content: center;
}

.collection__item__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.0625rem;
  padding: 0 0.9375rem;
  transition: background-color .4s linear;
  background-color: rgb(0 187 238);
  font-family: 'P22UndergroundCY-LtS';
  font-size: .5em;
}
.collection__item__meta a{
  color:
  white;
  text-decoration:
  none;
  /* font-size: .5em; */
  font-weight: bold;
}
.collection__main {
  position: relative;
  height: auto;
  width: 100%;
  flex-grow: 1;
  /* overflow: scroll; */
}

@media (min-width: 43.8125rem){.--small .collection__grid {
  /* grid-gap: 0.9375rem; */
  grid-template-columns: repeat(3,1fr);
}}

@media (min-width: 64.0625rem){
  .collection__grid {
  grid-gap: 1.5625rem;
  }
}

@media (min-width: 64.0625rem){
  .collection__padding {
      padding: 0 4.5rem;
  }
}

@media (min-width: 43.8125rem){
  .collection__padding {
      padding: 0 2.25rem;
  }
}


.collection__grid {
  display: grid;
  grid-gap: 1.5625rem;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
  /* overflow-y: scroll; */
  grid-template-columns: 1fr;
}

.collection {
  --filters-width: max(min(27rem,31%),16.5625rem);
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 1.625rem;
  background: #fff;
}

.collection__item__image {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  background: #dadada;
}
.collection__item__image img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: opacity .4s linear .05s,transform .7s cubic-bezier(.19,1,.22,1);
}

h1.collection__title {
  color: white;
  font-family: 'P22UndergroundCY-LtS';
}

.yourCollectionTitle {
  margin-top: 3em;
  FONT-WEIGHT: 500;
  FONT-WEIGHT: 500;
  margin-bottom: 3em;
}

@media (max-width: 700px){
  /* grid-gap: 0.9375rem; */
  .yourCollectionTitle, .collection__grid{padding: 0 20px;}
 
}

@media (max-width: 400px){
  /* grid-gap: 0.9375rem; */
  .yourCollectionTitle{font-size: 2.5em;;}
 
}


.collection__item__meta {
  display: none;
}


span.clickToView {
  font-size: .5em;
  display: block;
  font-family:
  'P22UndergroundCYPro-Light';
  font-weight: 100;
  color: white;
}
.collection__item:hover img {
  transform: scale3d(1.15,1.15,1.15);
   -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

}

.collection__item img{
    -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition:.5s ease-in-out;
  transition: .5s ease-in-out;
}

button.btn.chakra-button.currentClaiming {
  animation: blinker 2s linear infinite;
    opacity: .75;
}

@keyframes blinker {
  50% {
    opacity: .25;
  }
}
button.btn.btnMargin.paychoice {
  margin-left: 20px;
}
.threeOptionsRow {
  display: flex;
flex-wrap: wrap;
flex-direction: column;
}
iframe{
  border: transparent;
  border-style: none;

}
.chakra-stack.css-1au6mu0 {
  display: none;
}
@media (max-width: 400px){
  /* grid-gap: 0.9375rem; */
  button.btn.btnMargin.paychoice {
    margin-left: 0px;
    margin-top:20px
}
.mintingRow.paychoicerow {

  flex-wrap: wrap;
}
}

@media (max-width: 400px){
  /* grid-gap: 0.9375rem; */
 h1 {font-size:2.5em!important;}
 h1.collection__title.collection__padding {    font-size: unset!important;}
}